/* eslint-disable max-len */
import { envVal } from '@root/config/config';

const year = new Date().getFullYear();

export const muzaContent = {
  timezone: 'Europe/Moscow',
  yandexMapState: {
    center: [55.7522, 37.6155],
    zoom: 9,
  },
  locale: { _id: 1 },
  title: '2DO2GO Афиша России',
  subTitle: 'Федеральная афиша мероприятий',
  mainBanner: {
    hide: true,
    title: '',
    text: '',
    links: []
  },
  useBvi: false,
  phone: '8 800 200-09-42',
  mail: 'bilet@2do2go.ru',
  agreementLink: '/static/public-offer-22-07-2024.pdf',
  privacyPolicyLink: '/static/privacy-policy-22-07-2024.pdf',
  refundPolicyLink: '/static/refund-policy-22-07-2024.pdf',
  socials: {
    ok: 'https://ok.ru/group/59637846179866',
    vk: 'https://vk.com/welcomekursk',
    yaDzen: 'https://zen.yandex.ru/id/62330928ffe8ad75b0cad210',
    telegram: 'https://t.me/welcomekursk',
    facebook: 'https://www.facebook.com/%D0%A1%D0%BE%D0%BB%D0%BE%D0%B2%D1%8C%D0%B8%D0%BD%D1%8B%D0%B9' +
      '-%D0%BA%D1%80%D0%B0%D0%B9-110430014815223/?ref=page_internal',
    instagram: 'https://www.instagram.com/welcomekursk/',
  },
  socialTitles: {
    vk: 'вконтакте',
    telegram: 'Telegram',
  },
  subscribeForm: {
    title: 'Будьте в курсе всех новостей',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      { title: 'Места', to: '/places' },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
    ],
  },
  footer: {
    title: '',
    logoPath: '/images/logo_footer.svg',
    systemName: `© ${year}. 2DO2GO Афиша России. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      { title: 'Афиша', to: '/events' },
      { title: 'Абонементы', to: '/ticketsbundles' },
      { title: 'Места', to: '/places' },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
    ]
  },
  analytics: {
    yandex: envVal({ production: '' }, null),
    gtm: null,
    pixel: envVal({ production: '' }, null),
    goals: {
      paidVisit: 'events_visit_muza',
      paidBuy: 'buy_ticket_muza',
      freeVisit: 'events_visits_muza',
      freeNoTicketsVisit: 'noactive_free_muza',
      freeBuy: 'freeandzero_muza',
      externalLinkVisit: 'events_visit_redirect_muza',
      externalLinkBuy: 'buy_redirect_muza',
      ticketsAdd: 'entity_ticket_add',
    }
  },
  unisenderNewsPopup: {
    id: null,
  },
  componentsConfig: {
    header: 'default',
    footer: 'default',
    heroBlock: 'banner',
    socialCardIconVk: 'v2',
    socialCardIconTg: 'v2',
    pages: {
      index: {},
      places: {},
      singlePlace: {},
    },
  },
  seo: {
    main: '2DO2GO Афиша России — Федеральная афиша мероприятий',
    pages: {
      index: {
        title: () => '2DO2GO Афиша России — Федеральная афиша мероприятий',
        description: () => '2DO2GO Афиша России — Федеральная афиша мероприятий',
        titleOg: () => '2DO2GO Афиша России — Федеральная афиша мероприятий'
      },
      main: { title: '2DO2GO Афиша России — Федеральная афиша мероприятий' },
    },
  },
};
